import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from '../../../environments/environment';
import {Constant} from '../../share/constant';

@Injectable()
export class OrderService {
  constructor(private _httpClient: HttpClient) {

  }

  allProduct() {
    return this._httpClient.get(`${environment.serviceBaseURL}${Constant.url.product}`);
  }

  allProductNameWithColor() {
    return this._httpClient.get(`${environment.serviceBaseURL}${Constant.url.product_name_with_color}`);
  }

  colorByProductId(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.color_by_product_id}`, param);
  }

  sizeByColorId(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.size_by_color_id}`, param);
  }

  placeOrder(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.order_place}`, param);
  }

  orderList(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.user_order_list}`, param);
  }

  orderListItems(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.order_list_item}`, param);
  }

  orderClaim(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.order_claim}`, param);
  }

  addClaim(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.add_claim}`, param);
  }

  addProductGroup(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.add_product_group}`, param);
  }

  claimOrderList(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.claim_list}`, param);
  }

  claimOrderDetails(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.claim_details}`, param);
  }

  updateRecivedAmounts(param) {
			return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.updateRecivedAmounts}`, param);
	}
	updateDiscountedAmount(param) {
		return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.updateDiscountedAmount}`, param);
	}

  usersOrderList(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.order_all}`, param);
  }
  usersOrderList30Days(param) {
			return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.order_30_days}`, param);
	}

  updateOrderStatus(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.update_order_status}`, param);
  }

  updateClaimStatus(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.update_claim_status}`, param);
  }

  cancelOrder(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.cancel_order}`, param);
  }

  receivedPaymentOrder(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.order_payment_received}`, param);
  }

  productCode(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.product_code}`, param);
  }

  agentCommission(param) {
    return this._httpClient.post(`${environment.serviceBaseURL}${Constant.url.agent_commission}`, param);
  }

}
